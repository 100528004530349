import data from "./art.js";
import Image from "./Image";
// Import Swiper React components
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Carousel = () => {
  return (
    <Swiper
      spaceBetween={20}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      navigation={true}
      rewind={true}
      modules={[Autoplay, Navigation]}
      className="carousel"
    >
      {data.map((i) => (
        <SwiperSlide>
          <Image img={i} key={i.num} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
