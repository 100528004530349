const Image = (props) => {
  return (
    <div className="image">
      <img src={"/art/" + props.img.num + ".jpg"} alt="Art" />
      <div className="image-text">
        <div className="text-title">
          "{props.img.title}" <span>— {props.img.year}</span>
        </div>
        <div className="text-meta">{props.img.medium}</div>
        <div className="text-meta">{props.img.resolution}</div>
      </div>
    </div>
  );
};

export default Image;
