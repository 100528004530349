const art = [
  {
    title: "Grass book",
    year: 2023,
    medium: "Found object sculpture and photography",
    num: 11,
    resolution: "3000 x 3000 px",
  },
  {
    title: "Dummo Loop",
    year: 2023,
    medium: "Marker and digital multimedia",
    num: 12,
    resolution: "3000 x 3000 px",
  },
  {
    title: "Untitled (Self portrait)",
    year: 2023,
    medium: "Marker and digital multimedia",
    num: 10,
    resolution: "3024 x 4032 px",
  },
  {
    title: "Spiritbond",
    year: 2023,
    medium: "Marker and digital multimedia",
    num: 9,
    resolution: "6000 x 6000 px",
  },
  {
    title: "New Sight, New Vision",
    year: 2022,
    medium: "Marker and digital multimedia",
    num: 7,
    resolution: "2825 x 3767 px",
  },
  {
    title: "Untitled",
    year: 2022,
    medium: "Pencil and digital multimedia",
    num: 3,
    resolution: "3024 x 4032 px",
  },
  {
    title: "Mother",
    year: 2021,
    medium: "Digital multimedia",
    num: 1,
    resolution: "3024 x 4032 px",
  },
  {
    title: "Portal",
    year: 2021,
    medium: "Pencil and digital painting multimedia",
    num: 5,
    resolution: "3840 x 2160 px",
  },
  {
    title: "Bleed",
    year: 2021,
    medium: "Pencil and digital painting multimedia",
    num: 2,
    resolution: "3840 x 2160 px",
  },
  {
    title: "Heights",
    year: 2020,
    medium: "Digital painting",
    num: 6,
    resolution: "1920 x 1080 px",
  },
  {
    title: "Depths",
    year: 2019,
    medium: "Digital painting",
    num: 4,
    resolution: "1920 x 1080 px",
  },
];

export default art;
