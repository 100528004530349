import "./App.css";
import { Link, animateScroll as scroll } from "react-scroll";
import { useState } from "react";
import Carousel from "./Carousel";

function App() {
  let [active, setActive] = useState(false);

  return (
    <div className="main-site">
      <header>
        <div className="header-name">Ernesto Dagnino</div>
        <div className="header-links">
          <a
            href="#main-site"
            onClick={(e) => {
              e.preventDefault();
              scroll.scrollToTop();
            }}
          >
            Artworks
          </a>
          <Link to="about" smooth>
            About
          </Link>
          <Link to="contact" smooth>
            Contact
          </Link>
        </div>
        <div id="mobile-menubutton" onClick={() => setActive(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </div>
        <div id="mobile-links" className={active ? "active" : ""}>
          <svg
            id="ml_close"
            onClick={() => setActive(false)}
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <div class="mobile-links-links">
            <a
              href="#main-site"
              onClick={(e) => {
                e.preventDefault();
                scroll.scrollToTop();
                setActive(false);
              }}
            >
              Artworks
            </a>
            <Link to="about" smooth onClick={() => setActive(false)}>
              About
            </Link>
            <Link to="contact" smooth onClick={() => setActive(false)}>
              Contact
            </Link>
          </div>
        </div>
      </header>
      <div className="section" id="artworks">
        <Carousel />
      </div>
      <div className="section" id="about">
        <div className="about-content">
          Born in 2003 in Mesa, Arizona, USA, Ernesto “Nes” Dagnino Jr. also
          known by his online moniker “Nesulosity” is a Mexican-American
          multimedia digital artist based and working in Racine, Wisconsin, USA.
          With an upbringing in an era defined by technology and media, Ernesto
          draws inspiration from how people connect to each other and the
          natural world through art, community and illicit substance use with
          the internet as the ultimate middleman. He uses a variety of digital
          and traditional mediums such as pen and pencil, oil pastels and
          acrylic paint to create compelling digital works of art. In his
          downtime from his work, Ernesto is the creator of and residing team
          member of a notable online community known as artbyFORM, whose work
          aims to bring together a vast underground community of creatives of
          all different mediums through events supporting charitable causes.
        </div>
      </div>
      <div className="section" id="contact">
        <div className="contact-email">
          <div className="contact-email-label">For inquiries of any kind</div>
          <div className="contact-email-address">
            <a href="mailto:nesulosity@gmail.com">nesulosity@gmail.com</a>
          </div>
        </div>
        <div className="contact-bar"></div>
        <div className="contact-links">
          <a href="https://twitter.com/nesulosity">
            <img src="/svgs/twitter.svg" alt="twitter" />
          </a>
          <a href="https://instagram.com/nesulosityart">
            <img src="/svgs/instagram.svg" alt="instagram" />
          </a>
        </div>
        <div className="scrolltotop" onClick={() => scroll.scrollToTop()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
            />
          </svg>
          Scroll to top
        </div>
      </div>
      <div className="credits">
        <p>Designed by Nesulosity & vintroxx</p>
        <p>Developed by vintroxx</p>
        <p>
          Special thanks to the <a href="https://artbyform.com">artbyFORM</a>{" "}
          community
        </p>
      </div>
    </div>
  );
}

export default App;
